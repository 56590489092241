import React from 'react';

import { getPositionsGenerator } from 'utils/analytics';

import AemComponent from 'components/AemComponent';
import ProductPod from 'components/ProductPod';

export const carouselSlideMapper = (items, productPodProps = {}, wrapperProps = {}) => {
  if (!items || !Array.isArray(items) || !items.length > 0) {
    return null;
  }

  const getPositions = getPositionsGenerator();

  return items.map((carouselItem, index) => {
    const { aemComponent, cqResponsive, searchProduct } = carouselItem;
    const { wrapperId } = wrapperProps;
    const positions = getPositions(cqResponsive);

    if (aemComponent) {
      const { componentId } = aemComponent;

      return (
        <AemComponent
          component={{
            ...aemComponent,
            wrapperId,
            index,
          }}
          key={componentId}
          properties={{
            fullSize: true,
            key: index,
            positions,
          }}
        />
      );
    }

    const id = searchProduct?.id || searchProduct;

    if (id) {
      return (
        <ProductPod
          {...{
            ...productPodProps,
            index,
            positions,
            id,
            wrapperId,
          }}
          key={`${JSON.stringify(positions)}-${id}`}
        />
      );
    }

    return null;
  });
};
