import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import propTypes from 'prop-types';

import Carousel from 'components/Carousel';
import ExpansionPanel from '@johnlewispartnership/wtr-ingredients/ingredients/ExpansionPanel';
import { useClickToBuyContextDecisioner } from 'content/hooks/useClickToBuyContextDecisioner';

import { carouselSlideMapper } from 'utils/carouselSlideMapper';
import { pushProductImpressions } from 'utils/gtm';

// [SSR][OK] useEffect does not fire on server
// eslint-disable-next-line no-restricted-imports
import { mediaBreakpointCurrent } from 'utils/mediaQueries';

import styles from './index.scss';

const defaultCarouselSettings = {
  accessibility: true,
  autoplay: false,
  arrows: true,
  dots: true,
  infinite: false,
  lazyLoad: true,
  slidesToShow: 1,
  swipeToSlide: true,
};

const productImpression = (data, products, taxonomyLevel) => index => {
  if (data?.items[index] && data?.componentId && products) {
    pushProductImpressions({
      listType: 'Click To Buy',
      productList: [data?.items[index]],
      products,
      slideIndex: index,
      taxonomyLevel,
      wrapperId: data?.componentId,
    });
  }
};

const ProductCarousel = ({
  componentId,
  cqResponsiveClasses,
  isInEditor = undefined,
  items,
  products,
  searchType,
  taxonomyLevel,
  ...componentProps
}) => {
  const { isAccordionOpen } = useClickToBuyContextDecisioner();
  const [hideArrows, setHideArrows] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  const componentData = { componentId, items, ...componentProps };

  const productPodProps = {
    fullSize: true,
    hasError: false,
    searchType,
    onSlidedownToggle: setHideArrows,
  };

  const wrapperProps = {
    wrapperId: componentId,
  };

  useEffect(() => {
    // if on mobile breakpoint when mounting, then don't push the event.
    if (mediaBreakpointCurrent() !== 'xs') {
      productImpression({ componentId, items }, products, taxonomyLevel)(0);
    }

    // products isn't stable as a property and so it can't be relied on.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isAccordionOpen) {
      productImpression(componentData, products, taxonomyLevel)(0);
    }
    // trigger the analytics when the accordion is opened, independant of props changing.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAccordionOpen]);

  const buttonLabel = 'product slide';

  const carouselSettings = {
    ...defaultCarouselSettings,
    afterChange: productImpression(componentData, products, taxonomyLevel),
    beforeChange: (oldIndex, newIndex) => {
      // any component can listen to this and react eg. pod can close slidedown overlay
      document.dispatchEvent(new Event('slickSlideChanged'));
      if (currentIndex !== newIndex) {
        setCurrentIndex(newIndex);
      }
    },
    customPaging: i => (
      <button type="button" aria-label={`${buttonLabel} ${i + 1}`}>
        {i + 1}
        {i === currentIndex && <span className="sr-only">Current slide</span>}
      </button>
    ),
  };

  return (
    <div
      className={classNames(styles.expansionPanelWrapper, cqResponsiveClasses, {
        [styles.fullWidth]: isInEditor,
        [styles.hideArrows]: hideArrows,
      })}
      data-testid="Product Carousel"
    >
      <ExpansionPanel
        isOpen={isAccordionOpen}
        alwaysOpen={{ xs: false, sm: true, md: true, lg: true, xl: true }}
      >
        <Carousel
          data={componentData}
          settings={carouselSettings}
          fullSize
          isOpen={isAccordionOpen}
          buttonLabel={buttonLabel}
        >
          {carouselSlideMapper(items, productPodProps, wrapperProps)}
        </Carousel>
      </ExpansionPanel>
    </div>
  );
};

ProductCarousel.defaultProps = {
  componentId: undefined,
  cqResponsiveClasses: undefined,
  isInEditor: undefined,
  items: undefined,
  products: undefined,
  searchType: '',
  taxonomyLevel: '',
};

ProductCarousel.propTypes = {
  componentId: propTypes.string,
  cqResponsiveClasses: propTypes.arrayOf(propTypes.string),
  isInEditor: propTypes.bool,
  items: propTypes.arrayOf(propTypes.shape({})),
  products: propTypes.objectOf(propTypes.shape({ id: propTypes.string })),
  searchType: propTypes.string,
  taxonomyLevel: propTypes.string,
};

export default ProductCarousel;
