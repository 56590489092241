import { connect } from 'react-redux';

import { getCurrentPageDepth } from 'redux/modules/page/selectors';
import { getCustomerSearchTerm } from 'redux/modules/search-and-browse/selectors/search';
import { getProducts } from 'redux/modules/entities/selectors/products';

import ProductCarousel from './ProductCarousel';

export default connect(state => ({
  products: getProducts(state),
  searchTerm: getCustomerSearchTerm(state),
  taxonomyLevel: getCurrentPageDepth(state),
}))(ProductCarousel);
