import { useClickToBuyContext } from '@johnlewispartnership/wtr-content-component-library/dist/component-library/components/ClickToBuy';
import { ClickToBuyContext } from 'components/ClickToBuy/ClickToBuyInner';
import { useContext } from 'react';
import useComponentLibraryDecision from './useComponentLibraryDecision';

export const useClickToBuyContextDecisioner = () => {
  const isLibrary = useComponentLibraryDecision();
  const legacyContext = useContext(ClickToBuyContext);
  const context = useClickToBuyContext();

  return isLibrary ? context : legacyContext;
};
