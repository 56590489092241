import React from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { getProductConflictById } from 'redux/modules/conflicts/selectors';
import { RichTextNode } from 'components/RichText/types';
import { getFeatureFlags } from 'utils/feature-flags';
import { TEMPORARILY_UNAVAILABLE, NOT_AVAILABLE } from 'constants/conflicts';
import Carousel from '.';
import ProductCarousel from '../ProductCarousel';
import styles from './RecommendedCarousel.scss';

export interface Product {
  searchProduct: {
    id: string;
    lineNumber: string;
    name: string;
  };
}

export default ({
  items,
  subTitle,
  title,
  cqResponsiveClasses,
  insideWrapper,
  backgroundColor,
  textAndControlsColor,
  link,
  image,
  ...componentProps
}: {
  items: [Product];
  subTitle: RichTextNode;
  title: RichTextNode;
  insideWrapper: boolean;
  cqResponsiveClasses: string[];
  backgroundColor?: string;
  textAndControlsColor?: string;
  link?: {
    altText: string;
    url: string;
    newWindow: boolean;
  };
  image?: {
    landscapeImage: {
      dynamicImages: {
        defaultQuality: string;
        highQuality: string;
        highQuality85: string;
        highQuality90: string;
        highQuality95: string;
      };
    };
  };
}) => {
  if (!getFeatureFlags().EoS_aemCarousel || insideWrapper) {
    return (
      <ProductCarousel
        {...componentProps}
        items={items}
        cqResponsiveClasses={cqResponsiveClasses}
      />
    );
  }

  const productsForCarousel = items
    // removes unavailable or sold out products
    ?.filter(({ searchProduct }) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const conflict = useSelector(state => getProductConflictById(state, searchProduct));

      return !(
        conflict?.messages?.shortItem === TEMPORARILY_UNAVAILABLE ||
        conflict?.messages?.shortItem === NOT_AVAILABLE ||
        conflict?.outOfStock
      );
    });

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const titleContent = title?.blocks[0]?.children?.[0]?.text;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const subTitleContent = subTitle?.blocks[0]?.children?.[0]?.text;

  const linkData = {
    text: link?.altText,
    type: 'link',
    ...link,
  };

  return (
    <div data-testid="jx-prod-carousel" className={styles.removeMarginInMobile}>
      <Carousel
        {...componentProps}
        products={productsForCarousel}
        subTitle={subTitleContent}
        titleDesktop={titleContent}
        titleMobileAndTablet={titleContent}
        backgroundColor={backgroundColor}
        buttonsTheme={textAndControlsColor}
        divider
        linkData={linkData}
        className={classNames(cqResponsiveClasses)}
        image={
          image?.landscapeImage.dynamicImages?.highQuality95
            ? { imageUrl: image?.landscapeImage.dynamicImages?.highQuality95 }
            : undefined
        }
      />
    </div>
  );
};
