import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Slider from 'react-slick';

import aemComponent from 'constants/data-shapes/aem-component';

import CarouselArrow from './Arrow';

import styles from './index.scss';

const Carousel = ({ fullSize, settings, children, buttonLabel }) => {
  const sliderSettings = {
    ...settings,
    nextArrow: <CarouselArrow name="Next" label={`Next ${buttonLabel}`} />,
    prevArrow: <CarouselArrow name="Previous" label={`Previous ${buttonLabel}`} />,
  };

  return (
    <div
      className={classnames(styles.carouselWrapper, {
        [styles.fullSize]: fullSize,
        cqResponsiveClasses: fullSize,
      })}
      data-testid="carousel-slides-wrapper"
    >
      <Slider {...sliderSettings}>{children}</Slider>
    </div>
  );
};

Carousel.displayName = 'Carousel';

Carousel.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  data: aemComponent().isRequired,
  fullSize: PropTypes.bool,
  gridColumns: PropTypes.number,
  settings: PropTypes.shape({
    accessibility: PropTypes.bool,
    autoplay: PropTypes.bool,
    arrows: PropTypes.bool,
    dots: PropTypes.bool,
    infinite: PropTypes.bool,
    lazyLoad: PropTypes.bool,
    slidesToShow: PropTypes.number,
    swipeToSlide: PropTypes.bool,
  }),
  buttonLabel: PropTypes.string,
};

Carousel.defaultProps = {
  children: null,
  fullSize: false,
  gridColumns: 12,
  settings: {
    adaptiveHeight: true,
    accessibility: true,
    autoplay: false,
    arrows: true,
    dots: true,
    infinite: false,
    lazyLoad: false,
    slidesToShow: 1,
    swipeToSlide: true,
  },
  buttonLabel: 'slide',
};

export default Carousel;
