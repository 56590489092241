import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { dataAttributesGenerator } from 'utils/data-attribute-generator';

// https://github.com/akiran/react-slick/issues/1195
// eslint-disable-next-line react/prop-types
const SlickButtonFix = ({ children, currentSlide, slideCount, ...props }) => (
  <button {...props} type="button">
    {children}
  </button>
);

const CarouselArrow = memo(props => {
  const { name, label, ...rest } = props;
  return (
    <div
      {...dataAttributesGenerator({
        actiontype: name.toLowerCase(),
        origincomponent: 'Carousel',
        shortdescription: `${name.toLowerCase()} slide navigation`,
      })}
    >
      <SlickButtonFix name={name} {...rest}>
        {label || name}
      </SlickButtonFix>
    </div>
  );
});

CarouselArrow.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  currentSlide: PropTypes.number,
  name: PropTypes.string.isRequired,
  slideCount: PropTypes.number,
  label: PropTypes.string,
};

CarouselArrow.defaultProps = {
  children: null,
  currentSlide: 0,
  slideCount: 0,
  label: '',
};

CarouselArrow.displayName = 'CarouselArrow';

export default CarouselArrow;
